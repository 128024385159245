@import "../../../../globals/styles/_fonts.scss";@import "../../../../globals/styles/_media-queries.scss";@import "../../../../globals/styles/_variables.scss";.picturePreparationStep {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  padding-top: 20px;
  min-height: calc(100dvh - 50px);

  @include mobile {
    min-height: calc(100dvh - 30px);
  }
}

.picturePreparationStep__body {
  display: flex;
  flex-direction: column;
}

.picturePreparationStep__visual {
  position: relative;
  width: 100%;
}

.picturePreparationStep__visual__image {
  width: 100%;
  object-fit: cover;
}

.picturePreparationStep__textContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-block: 32px;
  text-align: center;
}

.picturePreparationStep__title__colored {
  color: var(--primary-color);
}

.picturePreparationStep__buttonContainer {
  margin-block: 0 32px;
}

.picturePreparationStep__fileInput {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.picturePreparationStep__downloadPhotoButton {
  display: block;
  margin: 32px auto;
  cursor: pointer;
  text-align: center;
  font-family: "Barlow", sans-serif;
  color: var(--primary-color);
}
